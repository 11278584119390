module.exports = {
  siteTitle: 'Kiwiplan Cloud', // <title>
  manifestName: 'Kiwiplan Cloud',
  manifestShortName: 'Kiwiplan', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/kiwiplan.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'kiwiplan.cloud',
  subHeading:
    "Journey to the cloud: create your personal sandbox account.",
  socialLinks: [],
};
