import React, { Component } from 'react';
import axios from 'axios';

export class EmailForm extends Component {
  constructor() {
    super();
    this.state = { message: '' };
    this.onSubmit = this.onSubmit.bind(this);
    this.login = this.login.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({ emailAddress : e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    let currentStatus = this.state.message || '';
    if (currentStatus.length === 0) {
      let emailAddressToSubmit = this.state.emailAddress || '';
      emailAddressToSubmit = emailAddressToSubmit.trim();
      if (emailAddressToSubmit.length > 0) {
        this.setState({ message: 'Saving...' });
        const emailData = { emailAddress : emailAddressToSubmit };
        console.log('Saving ' + emailAddressToSubmit);
        axios.post('https://api.kiwiplan.cloud/v1/accounts', emailData)//, options)
          .then(response => {
              console.log('Success');
              this.setState({ message: 'Done. Allow 48hrs for manual activation.' });
              setTimeout(() => {
                this.setState({ message: '' });
              }, 12000);
            })
            .catch(error => {
              let errorMessage = error?.response?.data || 'reason unknown';
              console.log('Failed: ' + errorMessage);
              this.setState({ message: ':-( Sorry, the request failed. ' + errorMessage });
              setTimeout(() => {
                this.setState({ message: '' });
              }, 8000);
          });
      }
    }
  }

  login(e) {
    this.setState({ message: 'Taking you there...' });
    window.location='https://kiwiplan.awsapps.com/start/';
  }

  render() {
    const { message } = this.state;
    return (
      <form id="signup-form" onSubmit={this.onSubmit} method="post" action="#">
        <input
          type="email"
          name="email"
          id="email"
          placeholder="Email Address"
          onChange={this.handleChange}
        />
        <input type="submit" className="submit-button" value="Create" />
        <input type="button" className="action-button" value="Sign In" onClick={this.login} />
        <span className={`${message ? 'visible success' : ''} message`}>
          {message}
        </span>
      </form>
    );
  }
}

export default EmailForm;
